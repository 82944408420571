// import React from "react";
// import ToolCard from "./ToolCard.js";

// const ToolCardRenderer = ({ state }) => {
// 	// Sort the state array by star rating in descending order
// 	const sortedState = [...state].sort((a, b) => b.stars - a.stars);

// 	return (
// 		<div className="card container card-container-layout border-none">
// 			<div
// 				className="row"
// 				style={{
// 					justifyContent: "center",
// 					flexWrap: "wrap",
// 				}}
// 			>
// 				{sortedState.map((tool) => (
// 					<ToolCard key={tool.id} tool={tool} />
// 				))}
// 			</div>
// 		</div>
// 	);
// };

// export default ToolCardRenderer;

// import React, { useState } from "react";
// import ToolCard from "./ToolCard.js";

// const ToolCardRenderer = ({ state }) => {
// 	const [visibleCards, setVisibleCards] = useState(12); // Initially, display 30 cards

// 	// Function to load more cards
// 	const loadMore = () => {
// 		setVisibleCards((prevVisibleCards) => prevVisibleCards + 12);
// 	};

// 	const displayedState = state.slice(0, visibleCards);

// 	return (
// 		<div className="card container card-container-layout border-none">
// 			<div
// 				className="row"
// 				style={{
// 					justifyContent: "center",
// 					flexWrap: "wrap",
// 				}}
// 			>
// 				{displayedState.map((tool) => (
// 					<ToolCard key={tool.id} tool={tool} />
// 				))}
// 			</div>
// 			{visibleCards < state.length && (
// 				<div className="text-center mt-3">
// 					<button className="btn btn-primary" onClick={loadMore}>
// 						Load More Magical Tools
// 					</button>
// 				</div>
// 			)}
// 		</div>
// 	);
// };

// export default ToolCardRenderer;

// import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
// import ToolCard from "./ToolCard.js";
// import Summarytableloadingskeleton from "./Summarytableloadingskeleton.js";

// const ToolCardRenderer = ({ state }) => {
// 	const [visibleCards, setVisibleCards] = useState(12);
// 	const [isLoading, setIsLoading] = useState(true); // Initially, set loading to true
// 	const containerRef = useRef(null); // Ref to the container element

// 	useEffect(() => {
// 		// Simulate a loading delay for demonstration purposes
// 		setTimeout(() => {
// 			setIsLoading(false);
// 		}, 2000);
// 	}, []);

// 	useLayoutEffect(() => {
// 		// Check if the container element is available and the cards are rendered
// 		if (containerRef.current) {
// 			setIsLoading(false); // Set loading to false once the cards are rendered
// 		}
// 	}, [containerRef]);

// 	const loadMore = () => {
// 		setVisibleCards((prevVisibleCards) => prevVisibleCards + 15);
// 	};

// 	const displayedState = state.slice(0, visibleCards);

// 	if (isLoading) {
// 		return (
// 			<div className="container">
// 				<Summarytableloadingskeleton />
// 			</div>
// 		);
// 	}

// 	return (
// 		<div
// 			ref={containerRef} // Set the ref to the container element
// 			className="card container card-container-layout border-none"
// 		>
// 			<div
// 				className="row"
// 				style={{
// 					justifyContent: "center",
// 					flexWrap: "wrap",
// 				}}
// 			>
// 				{displayedState.map((tool) => (
// 					<ToolCard key={tool.id} tool={tool} />
// 				))}
// 			</div>
// 			{visibleCards < state.length && (
// 				<div className="text-center mt-3">
// 					<button
// 						className="btn btn-primary load-more"
// 						onClick={loadMore}
// 					>
// 						Load More Magical Tools
// 					</button>
// 				</div>
// 			)}
// 		</div>
// 	);
// };

// export default ToolCardRenderer;

import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import ToolCard from "./ToolCard.js";
import Summarytableloadingskeleton from "./Summarytableloadingskeleton.js";

const ToolCardRenderer = ({ state }) => {
	const [visibleCards, setVisibleCards] = useState(12);
	const [isLoading, setIsLoading] = useState(true); // Initially, set loading to true
	const containerRef = useRef(null); // Ref to the container element

	useEffect(() => {
		// Simulate a loading delay for demonstration purposes
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	useLayoutEffect(() => {
		// Check if the container element is available and the cards are rendered
		if (containerRef.current) {
			setIsLoading(false); // Set loading to false once the cards are rendered
		}
	}, [containerRef]);

	const loadMore = () => {
		setVisibleCards((prevVisibleCards) => prevVisibleCards + 15);
	};

	// Sort the state array by star rating in descending order
	const sortedState = [...state].sort((a, b) => b.stars - a.stars);

	const displayedState = sortedState.slice(0, visibleCards);

	if (isLoading) {
		return (
			<div className="container">
				<Summarytableloadingskeleton />
			</div>
		);
	}

	return (
		<div className="container grayfrontpagecardcontainer ">
			<div
				ref={containerRef} // Set the ref to the container element
				className="card container card-container-layout border-non grayfrontpagecardcontainer"
			>
				<div
					className="row grayfrontpage"
					style={{
						justifyContent: "center",
						flexWrap: "wrap",
					}}
				>
					{displayedState.map((tool) => (
						<ToolCard key={tool.id} tool={tool} />
					))}
				</div>
				{visibleCards < state.length && (
					<div className="text-center mt-3 grayfrontpage">
						<button
							className="btn btn-primary load-more"
							onClick={loadMore}
						>
							Load More Magical Tools
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default ToolCardRenderer;
