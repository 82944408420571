import React, {
	createContext,
	useContext,
	useEffect,
	userEffect,
	useState,
} from "react";
import { auth } from "../firebase";
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
	sendPasswordResetEmail,
} from "firebase/auth";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState({});
	let signup = (email, password) => {
		console.log("signup");
		return createUserWithEmailAndPassword(auth, email.password);
	};
	let login = (email, password) => {
		return signInWithEmailAndPassword(auth, email, password);
	};
	let logout = () => {
		return signOut(auth);
	};
	const resetPassword = (email) => {
		return sendPasswordResetEmail(auth, email);
	};
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setCurrentUser(user);
		});
		return () => {
			unsubscribe();
		};
	}, []);
	const value = {
		currentUser,
		signup,
		login,
		logout,
		resetPassword,
	};
	return (
		<>
			<AuthContext.Provider value={value}>
				{children}
			</AuthContext.Provider>
		</>
	);
};

export default AuthProvider;

export const UserAuth = () => {
	return useContext(AuthContext);
};
