import React from "react";
import HarryAtlasHeadshot from "../img/people/HarryAtlasHeadshot.jpeg";
import ElieNabushosiHeadshot from "../img/people/ElieNabushosiHeadshot.jpeg";

const AboutTheAuthors = () => {
	return (
		<div className="container">
			<h1 className="summarytableheader mt-5">About the authors</h1>
			{/* <div className="card profile-header">
				<div className="body">
					<div className="row">
						<div className="col-xl-1" id="profileimagecolumnone">
							<div className="profile-image float-md-right">
								<a
									href="https://www.linkedin.com/in/harry-atlas-a6905b294/03b7bb189/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={HarryAtlasHeadshot}
										alt="Harry Atlas Headshot"
									/>
								</a>
							</div>
						</div>
						<div className="col">
							<strong>Harry Atlas</strong>
							<p>
								Harry is the Lead AI Researcher & editor at
								Savvy Genies. He previously worked at Open AI,
								Google & earned degrees from MIT(Computer
								Science) & Chicago Booth(MBA).
							</p>

							<div className="social-icon icon">
								<a
									title="Twitter"
									href="https://twitter.com/SavvyGenies"
								>
									<i className="fab fa-twitter"></i>
								</a>
								<a
									title="TikTok"
									href="https://www.tiktok.com/@yourtiktokprofile"
								>
									<i className="fab fa-tiktok"></i>
								</a>
								<a
									title="LinkedIn"
									href="https://www.linkedin.com/in/harry-atlas-a6905b294/03b7bb189/"
								>
									<i className="fab fa-linkedin"></i>{" "}
									
								</a>
								<a
									title="Instagram"
									href="https://www.instagram.com/savvygenies/"
								>
									<i className="fab fa-instagram"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<div className="card profile-header">
				<div className="body">
					<div className="row">
						<div className="col-xl-1" id="profileimagecolumntwo">
							<div className="profile-image float-md-right">
								<a
									href="https://www.linkedin.com/in/elie-nabushosi-mba-65308910a/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={ElieNabushosiHeadshot}
										alt="Elie Nabushosi Headshot"
									/>
								</a>
							</div>
						</div>
						<div className="col">
							<strong>Elie Nabushosi</strong>
							<p>
								Elie is the founder & CEO at Savvy Genies. He
								focuses on researching AI tools across various
								sectors. He holds an MBA degree from Southern
								Methodist University where he played DI
								football.
							</p>

							<div className="social-icon icon">
								<a
									title="Twitter"
									href="https://twitter.com/SavvyGenies"
								>
									<i className="fab fa-twitter"></i>
								</a>
								<a
									title="TikTok"
									href="https://www.tiktok.com/@yourtiktokprofile"
								>
									<i className="fab fa-tiktok"></i>
								</a>
								<a
									title="LinkedIn" // Add the title for LinkedIn
									href="https://www.linkedin.com/in/elie-nabushosi-mba-65308910a/" // Replace with your LinkedIn profile URL
								>
									<i className="fab fa-linkedin"></i>{" "}
									{/* Add LinkedIn icon */}
								</a>
								<a
									title="Instagram"
									href="https://www.instagram.com/savvygenies/"
								>
									<i className="fab fa-instagram"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutTheAuthors;
