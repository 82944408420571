export const categoryIcons = {
	"All Categories": "🌎",
	Writing: "✏️",
	Code: "💻",
	Image: "🖼️",
	Video: "📽️",
	Audio: "🔊",
	Design: "🎨",
	Assistant: "💬",
	Productivity: "✅",
	Lifestyle: "✌️",
	Business: "📈",
	Education: "📚",
	// Gaming: "🕹️"
};

export default categoryIcons;
