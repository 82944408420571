import React, { useRef, useEffect, useState } from "react";
import SavvyGeniesLogoSymbol2400x1800 from "../img/branding/SavvyGeniesLogoSymbol2400x1800.png";
import SavvyGeniesMainLogo from "../img/branding/SavvyGeniesMainLogo.svg";
import SavvyGeniesHeadLogo from "../img/branding/SavvyGeniesHeadLogo.png";
import Modal from "./Modal";
import emailjs from "@emailjs/browser";
import validator from "validator"; // Import the validator library

const ContentPageFooter = () => {
	const form = useRef();
	const modalRef = useRef();
	const [modal, setModal] = useState(false);
	const [newsLetEmail, setNewsLetEmail] = useState("");
	const [confirmed, setIsConfirmed] = useState("");
	const [emailValid, setEmailValid] = useState(true); // State variable for email validation

	const handleOutsideClick = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			toggleModal();
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	const toggleModal = () => {
		console.log("toggleModal called");
		setModal(!modal);
	};

	const sendEmail = (e) => {
		e.preventDefault();

		// Validate the email input
		if (!validator.isEmail(newsLetEmail)) {
			setEmailValid(false);
			return; // Do not proceed with sending the email
		}

		emailjs
			.sendForm(
				"service_fwm3mkb",
				"template_ibtqqo2",
				form.current,
				"fXGxAaZcmni10YULX"
			)
			.then(
				(result) => {
					console.log(result.text);
					setIsConfirmed(true);
					toggleModal(true);
				},

				(error) => {
					console.log(error.text);
				}
			);
		// Reset input fields after sign up
		setNewsLetEmail("");
		// setEmail("");
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			sendEmail(e);
		}
	};

	if (confirmed && modal) {
		return (
			<div className="modal">
				<div className="modal-content" ref={modalRef}>
					<div className="registration-form">
						<div className="form-icon">
							<img
								src={SavvyGeniesMainLogo}
								alt="User Icon"
								id="modalicon"
							/>
						</div>

						<h3 className="text-center">
							Thank you for signing up!
						</h3>
						<p className="text-center">
							You've successfully joined our newsletter.
						</p>
						<button
							type="button"
							className="close"
							onClick={toggleModal}
						>
							✖️
						</button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="d-flex flex-column h-100">
			<footer className="w-100  flex-shrink-0 contentpagefooter">
				<div className="container">
					<div className="row" id="footerrow">
						<div className="col-lg-4 col-md-6" id="footercolumn">
							<img
								src={SavvyGeniesHeadLogo}
								alt="Logo"
								className="logo"
								id="footerLogo"
							/>
							<p className="small text-muted footerlogosubtitle">
								Tools That Bring Your Genie Out
							</p>
						</div>
						<div className="col-lg-2 col-md-6">
							<h5 className="mb-3">💜 Our Top Picks</h5>
							<ul className="list-unstyled text-muted">
								<li>
									<a href="https://savvygenies.com/image">
										For Image Generators
									</a>
								</li>
								<li>
									<a href="https://savvygenies.com/engineers">
										For Engineers
									</a>
								</li>
								<li>
									<a href="https://savvygenies.com/writers">
										For Writers
									</a>
								</li>
								<li>
									<a href="https://savvygenies.com/marketers">
										For Marketers
									</a>
								</li>

								<li>
									<a href="https://savvygenies.com/business">
										For Business
									</a>
								</li>

								<li>
									<a href="https://savvygenies.com/audio">
										For Audio
									</a>
								</li>
								<li>
									<a href="https://savvygenies.com/video">
										For Video
									</a>
								</li>
							</ul>
						</div>
						<div className="col-lg-2 col-md-6">
							<h5 className="mb-3">📰 Trending News</h5>
							<ul className="list-unstyled text-muted">
								<li>
									<a href="https://savvygenies.com/AIreplacejobs">
										AI Will Replace Our Jobs, Even
										Engineers?
									</a>
								</li>
								<li>
									<a href="https://savvygenies.com/The-Boom-In-Image-Generators">
										Image Generation is Booming
									</a>
								</li>
								<li>
									<a href="https://savvygenies.com/Using-AI-to-Create-Video-and-Audio">
										The Transformation of Content Creation
									</a>
								</li>
								<li>
									<a href="https://savvygenies.com/The-Magic-of-Video-Generators-by-AI">
										Revolutionizing Video Creation
									</a>
								</li>
								<li>
									<a href="https://savvygenies.com/Programming-As-We-Knew-It-Is-Over">
										Programing as We Know It, is Over!{" "}
									</a>
								</li>
							</ul>
						</div>
						<div className="col-lg-4 col-md-6">
							<h5 className="mb-3">
								✉️ Join Our Daily Newsletter
							</h5>
							<p className="small text-muted">
								Keep Up With Our Latest Top Picks, Trends, &
								News
							</p>
							<form ref={form}>
								<div className="input-group mb-3">
									<input
										className={`form-control ${
											emailValid
												? "form-control"
												: "is-invalid" // Add "is-invalid" class for invalid email
										}`}
										type="text"
										id="newsletter-email" // Add an id to target this input
										name="newsletter_email" // This maps to emailjs template
										placeholder="Enter Your Email"
										aria-label="Recipient's username"
										aria-describedby="button-addon2"
										value={newsLetEmail}
										onChange={(e) => {
											setNewsLetEmail(e.target.value);
											setEmailValid(true); // Reset email validation on input change
										}}
										onKeyDown={handleKeyPress} // Listen for "Enter"
									/>
									<button
										className="btn btn-primary"
										id="button-addon2"
										type="button"
										onClick={sendEmail}
									>
										<i className="fa fa-paper-plane"></i>
									</button>
									<div className="invalid-feedback">
										⚠️ Please enter a valid email ⚠️
									</div>{" "}
								</div>
							</form>
							<p className="small text-muted mb-0">
								&copy; Copyrights. All rights reserved.{" "}
								<a className="text-primary" href="#">
									SavvyGenies.com
								</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default ContentPageFooter;
