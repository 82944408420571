import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import SavvyGeniesMainLogo from "../img/branding/SavvyGeniesMainLogo.svg";
import SavvyGeniesHeadLogo from "../img/branding/SavvyGeniesHeadLogo.png";
import DropdownMenu from "./DropdownMenu";
import Modal from "./Modal";
import emailjs from "@emailjs/browser";
import validator from "validator"; // Import the validator library
import SearchModal from "./SearchModal";

const Header = () => {
	const form = useRef();
	const modalRef = useRef();
	const [modal, setModal] = useState(false);
	const [newsLetEmail, setNewsLetEmail] = useState("");
	const [confirmed, setIsConfirmed] = useState("");
	const [isNavCollapsed, setIsNavCollapsed] = useState(true);
	const [emailValid, setEmailValid] = useState(true); // State variable for email validation

	const handleNavCollapse = () => {
		setIsNavCollapsed(!isNavCollapsed);
	};

	const toggleModal = () => {
		console.log("toggleModal called"); // Add this line
		setModal(!modal);
	};

	const sendEmail = (e) => {
		e.preventDefault();

		// Validate the email input
		if (!validator.isEmail(newsLetEmail)) {
			setEmailValid(false);
			return; // Do not proceed with sending the email
		}

		emailjs
			.sendForm(
				"service_fwm3mkb",
				"template_ibtqqo2",
				form.current,
				"fXGxAaZcmni10YULX"
			)
			.then(
				(result) => {
					console.log(result.text);
					setIsConfirmed(true);
					toggleModal(true);
				},

				(error) => {
					console.log(error.text);
				}
			);
		// Reset input fields after sign up
		setNewsLetEmail("");
		// setEmail("");
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			sendEmail(e);
		}
	};

	if (confirmed && modal) {
		return (
			<div className="modal">
				<div className="modal-content" ref={modalRef}>
					<div className="registration-form">
						<div className="form-icon">
							<img
								src={SavvyGeniesMainLogo}
								alt="User Icon"
								id="modalicon"
							/>
						</div>

						<h3 className="text-center">
							Thank you for signing up!
						</h3>
						<p className="text-center">
							You've successfully joined our newsletter.
						</p>
						<div className="modal-group-button">
							<p className="text-center">
								See Our Top Picks{"👇🏼"}
							</p>
							<a
								className="btn modalbutton"
								href="https://savvygenies.com/engineers"
							>
								{"👩🏻‍💻 "}For Engineers
							</a>
							<a
								className="btn modalbutton"
								href="https://savvygenies.com/writers"
							>
								{"✍🏿 "}For Writers
							</a>
							<a
								className="btn modalbutton"
								href="https://savvygenies.com/marketers"
							>
								{"💰 "}For Marketers
							</a>
						</div>
						<button
							type="button"
							className="close"
							onClick={toggleModal}
						>
							✖️
						</button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<header>
			<nav className="navbar navbar-expand-lg bg-white" id="myNav">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-lg-2 col-md-12" id="headercolumn1">
							<Link to="/" className="navbar-brand">
								<img
									src={SavvyGeniesHeadLogo}
									alt="Logo"
									className="logo"
									id="headerlogo"
								/>
							</Link>
						</div>
						<div className="col-lg-5 col-md-12" id="headercolumn2">
							<button
								className="navbar-toggler my-2"
								type="button"
								onClick={handleNavCollapse}
								aria-expanded={!isNavCollapsed}
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon"></span>
							</button>

							<div
								className={`collapse navbar-collapse ${
									isNavCollapsed ? "" : "show"
								}`}
								id="navbarNav"
							>
								<DropdownMenu
									title="For Engineers"
									items={[
										<Link
											to="/engineers"
											key="topPicksEngineer"
										>
											Our Top Picks
										</Link>,
										// "Compare & Review",
										// "News",
									]}
								/>
								<DropdownMenu
									title="For Writers"
									items={[
										<Link
											to="/writers"
											key="topPicksWriter"
										>
											Our Top Picks
										</Link>,
										// "Compare & Review",
										// "News",
									]}
								/>
								<DropdownMenu
									title="For Marketers"
									items={[
										<Link
											to="/marketers"
											key="topPicksMarketers"
										>
											Our Top Picks
										</Link>,
										// "Compare & Review",
										// "Review",
									]}
								/>
								{/* <DropdownMenu
									title="For Image Gen"
									items={[
										<Link to="/image" key="topPicksimage">
											Our Top Picks
										</Link>,
										// "Compare & Review",
										// "Review",
									]}
								/>
								<DropdownMenu
									title="For Business"
									items={[
										<Link
											to="/business"
											key="topPicksbusiness"
										>
											Our Top Picks
										</Link>,
										// "Compare & Review",
										// "Review",
									]}
								/>
								<DropdownMenu
									title="For Video"
									items={[
										<Link to="/video" key="topPicksvideo">
											Our Top Picks
										</Link>,
										// "Compare & Review",
										// "Review",
									]}
								/> */}
								<SearchModal/>
							</div>
						</div>
						
						<div className="col-lg col-md-12" id="headercolumn3">
							<div className="signup-button-container">
								<button
									type="button"
									id="signUp"
									className="btn"
									onClick={toggleModal}
								>
									🧞 Sign Up
								</button>

								<form ref={form}>
									<div className="input-group">
										<input
											className={`form-control ${
												emailValid
													? "form-control"
													: "is-invalid" // Add "is-invalid" class for invalid email
											}`}
											type="text"
											id="newsletter-email" // Add an id to target this input
											name="newsletter_email" // This maps to emailjs template
											placeholder="Join Our Daily Newsletter!"
											aria-label="Recipient's username"
											aria-describedby="button-addon2"
											value={newsLetEmail}
											onChange={(e) => {
												setNewsLetEmail(e.target.value);
												setEmailValid(true); // Reset email validation on input change
											}}
											onKeyDown={handleKeyPress} // Listen for "Enter"
										/>
										<button
											className="btn btn-primary"
											id="button-addon2"
											type="button"
											onClick={sendEmail}
										>
											<i className="fa fa-paper-plane"></i>
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</nav>
			{modal && <Modal toggleModal={toggleModal} />}
		</header>
	);
};

export default Header;
