import React, { useState } from "react";
import categoryIcons from "../shared/categoryIcons";
import HelmetComponent from "../seocomponents/helmet";
const buttonData = Object.entries(categoryIcons);

const HomePageTopContainer = ({ state, setState, TOOLS }) => {
	const [activeButton, setActiveButton] = useState("All Categories");

	const handleBtns = (word) => {
		const filtered =
			word === "All Categories"
				? TOOLS
				: TOOLS.filter((item) => item.category === word);

		// console.log("Filtered array:", filtered);
		setState(filtered);
		setActiveButton(word);
	};

	return (
		<div className="container ">
            <HelmetComponent
                title='Savvy AI Tools'
                description='Discover AI Tools To Become More Savvy & Productive'
                name='Savvy AI Tools'
                type='article' 
            />
			<div className="row ">
				<div className="col " id="homecontainerheader">
					<h2 id="homecontainerheaderfont">
						Discover The AI Tools To Be More Savvy & Productive
					</h2>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col" id="comparisonTable">
						{buttonData.map(([category, icon]) => (
							<button
								key={category}
								value={category}
								type="button"
								id="smallbutononfilter"
								className={`btn tabs button-zoom ${
									activeButton === category ? "active" : ""
								}`}
								onClick={() => handleBtns(category)}
							>
								<span
									role="img"
									aria-label={`${category} Emoji`}
								>
									{icon}
								</span>{" "}
								{category}
							</button>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomePageTopContainer;
