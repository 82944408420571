import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tools: [],
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setTools: (state, actions) => {
      state.tools = actions.payload
    }
  },
})

export const { setTools } = searchSlice.actions

export default searchSlice.reducer