import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./corecomponents/AuthContext";
import { hydrate, render } from "react-dom";
import { store } from './store'
import { Provider } from 'react-redux'

//const root = ReactDOM.createRoot(document.getElementById("root"));
const rootElement = document.getElementById("root");
const APP = (
	<React.StrictMode>
		<AuthProvider>
			<BrowserRouter>
				<Provider store={store}>
					<App />
				</Provider>
			</BrowserRouter>
		</AuthProvider>
	</React.StrictMode>
)
if (rootElement.hasChildNodes()) {
	hydrate(  APP , rootElement);
  } else {
	render(APP , rootElement);
  }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
