import React, { useState, useEffect } from "react";
import {
	doc,
	setDoc,
	getDoc,
	collection,
	getDocs,
	addDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { db, auth, storage } from "../firebase";
import { UserAuth } from "./AuthContext";
export default function Import() {
	const [data, setData] = useState("");
	const [images, setImages] = useState([]);
	const storage = getStorage();
	let navigate = useNavigate();
	let { logout } = UserAuth();
	// useEffect(() => {
	//     const listRef = ref(storage, '/Card Images/');
	//     listAll(listRef).then(async (res) => {
	//         let imagesList = [];
	//         await res.items.forEach(async(itemRef) => {
	//             await getDownloadURL(itemRef).then((url) => {
	//               let imageObj={name:itemRef.name,imageUrl:url}
	//               imagesList.push(imageObj)
	//             }).catch((error) => {
	//                 console.log(error)
	//             });
	//         });
	//         setImages(imagesList)
	//     }).catch((error) => {
	//     });
	// },[]);
	let handleLogout = () => {
		logout();
		navigate("/");
	};
	function testJSON(text) {
		if (typeof text !== "string") {
			return false;
		}
		try {
			JSON.parse(text);
			return true;
		} catch (error) {
			return false;
		}
	}
	const importData = async (e) => {
		e.preventDefault();
		let jsonIsValid = testJSON(data);
		if (jsonIsValid) {
			const obj = JSON.parse(data);
			obj.map(async (el) => {
				await getDownloadURL(
					ref(storage, "/Card Images/" + el.name + ".png")
				).then((url) => {
						console.log(url);
						if (url) {
							el["image"] = url;
							setDoc(doc(db, "TOOLS", el.name), el);
						}
				}).catch((error) => {
						el["image"] = "N/A";
						setDoc(doc(db, "TOOLS", el.name), el);
						console.log(error);
				});
			});
			alert("Tools data is updated");
		} else alert("Entered JSON data is not valid");
	};
	return (
		<div className="card container border-none mt-3 card-container-layout">
			<nav className="navbar bg-body-tertiary">
				<div className="container-fluid">
					<a
						className="navbar-brand btn"
						onClick={() => navigate("/")}
					>
						Home
					</a>
					<a
						className="navbar-brand btn"
						onClick={() => handleLogout()}
					>
						Logout
					</a>
				</div>
			</nav>
			<div className="mb-3 mt-3">
				<label
					htmlFor="exampleFormControlTextarea1"
					className="form-label"
				>
					Paste Your JSON here to import/update tools data
				</label>
				<textarea
					className="form-control"
					id="exampleFormControlTextarea1"
					style={{ minHeight: "145px" }}
					cols={5}
					rows={15}
					value={data}
					onChange={(evt) => setData(evt.target.value)}
				></textarea>
				<button
					type="button"
					className="btn btn-primary mt-3"
					onClick={importData}
				>
					Import
				</button>
			</div>
		</div>
	);
}
