import React, { useRef, useEffect, useState } from "react";
import SavvyGeniesMainLogo from "../img/branding/SavvyGeniesMainLogo.svg";
import emailjs from "@emailjs/browser";
import validator from "validator"; // Import the validator library

const Modal = ({ toggleModal }) => {
	const form = useRef();
	const modalRef = useRef();
	const [firstName, setFirstName] = useState("");
	const [confirmed, setIsConfirmed] = useState("");
	const [email, setEmail] = useState("");
	const [emailValid, setEmailValid] = useState(true); // State variable for email validation

	const handleOutsideClick = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			toggleModal();
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	//////////////Email Service/////////////////

	const sendEmail = (e) => {
		e.preventDefault();

		// Validate the email input
		if (!validator.isEmail(email)) {
			setEmailValid(false);
			return; // Do not proceed with sending the email
		}

		emailjs
			.sendForm(
				"service_fwm3mkb",
				"template_b6z9p6p",
				form.current,
				"fXGxAaZcmni10YULX"
			)
			.then(
				(result) => {
					console.log(result.text);
					setIsConfirmed(true);
				},
				(error) => {
					console.log(error.text);
				}
			);
		// Reset input fields after sign up
		setFirstName("");
		setEmail("");
	};

	if (confirmed) {
		return (
			<div className="modal">
				<div className="modal-content" ref={modalRef}>
					<div className="registration-form">
						<div className="form-icon">
							<img
								src={SavvyGeniesMainLogo}
								alt="User Icon"
								id="modalicon"
							/>
						</div>

						<h3 className="text-center">
							Thank you for signing up!
						</h3>
						<p className="text-center">
							You've successfully joined our newsletter.
						</p>
						<div className="modal-group-button">
							<p className="text-center">
								See Our Top Picks{"👇🏼"}
							</p>
							<a
								className="btn modalbutton"
								href="https://savvygenies.com/engineers"
							>
								{"👩🏻‍💻 "}For Engineers
							</a>
							<a
								className="btn modalbutton"
								href="https://savvygenies.com/writers"
							>
								{"✍🏿 "}For Writers
							</a>
							<a
								className="btn modalbutton"
								href="https://savvygenies.com/marketers"
							>
								{"💰 "}For Marketers
							</a>
						</div>
						<button
							type="button"
							className="close"
							onClick={toggleModal}
						>
							✖️
						</button>
					</div>
				</div>
			</div>
		);
	}

	//////////////Email Service/////////////////

	return (
		<div className="modal">
			<div className="modal-content" ref={modalRef}>
				<div className="registration-form">
					<form ref={form} onSubmit={sendEmail}>
						<div className="form-icon">
							<img
								src={SavvyGeniesMainLogo}
								alt="User Icon"
								id="modalicon"
							/>
						</div>
						<h5 className="text-center">
							Join Our Daily Newsletter
						</h5>
						<div className="form-group row">
							<input
								type="text"
								name="user_name"
								className="form-control item"
								id="name"
								placeholder="Name"
								required
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
							/>
						</div>
						<div className="form-group row">
							<input
								type="text"
								name="user_email"
								className={`form-control ${
									emailValid ? "form-control" : "is-invalid" // Add "is-invalid" class for invalid email
								}`}
								id="email"
								placeholder="Email"
								required
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
									setEmailValid(true); // Reset email validation on input change
								}}
							/>
							<div className="invalid-feedback row">
								⚠️ Not a valid email ⚠️
							</div>{" "}
						</div>
						{/* <div className="form-group">
							<textarea
								type="text"
								id="message"
								value={message}
								name="message"
								onChange={(e) => setMessage(e.target.value)}
							/>
						</div> */}
						<div className="form-group">
							<button
								type="submit"
								className="btn btn-block create-account"
								onClick={sendEmail}
							>
								Sign Up
							</button>
						</div>
						<div className="mt-5 text-center">
							<span>
								Join our <strong> strong </strong>community
								including <strong>Amazon</strong>,{" "}
								<strong>Apple</strong>, <strong>Google</strong>,{" "}
								<strong>The New York Times</strong>, and{" "}
								<strong>The Washington Post </strong>
								employees reading our free newsletter.
							</span>
						</div>
					</form>
				</div>
				<div>
					<button
						type="button"
						className="close"
						onClick={toggleModal}
					>
						✖️
					</button>
				</div>
			</div>
		</div>
	);
};

export default Modal;
