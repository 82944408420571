import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS
import "font-awesome/css/font-awesome.min.css"; // Font Awesome CSS

const ToolCard = ({ tool }) => {
	const { id, name, image, category, stars, description, externalCTA } = tool;

	// const visitedCount = Math.floor(Math.random() * (300 - 150 + 1) + 150);

	return (
		<div className="card col-12 col-md-6 col-lg-4 col-xl-3 m-1 product-card">
			<div className="d-flex flex-column align-items-center mb-3">
				<a href={externalCTA} target="_blank" rel="noopener noreferrer">
					<img
						className="imageoutline image-zoom"
						src={image}
						alt={name}
					/>
				</a>
			</div>
			<span className="titlefont">{name}</span>
			<span className="ratings">
				{Array.from(
					{ length: Math.max(parseInt(stars, 10) || 0, 3) }, // Ensure a minimum of 3 stars
					(_, index) => (
						<i key={index} className="fa fa-star"></i>
					)
				)}
			</span>

			{/* <span className="ratings">
				{typeof stars === "number" && !isNaN(stars) ? (
					[...Array(Math.floor(stars))].map((_, index) => (
						<i key={index} className="fa fa-star"></i>
					))
				) : (
					<span>No ratings available</span>
				)}
			</span> */}

			<span className="categoryfont">{category}</span>
			<span className="descriptionfont" id="toolcarddescription">
				{description}
			</span>
			<div className="d-flex justify-content-between install mt-3">
				{/* <span>Visited {visitedCount} times</span> */}
				<span className="text-primary">
					<a
						href={externalCTA}
						target="_blank"
						rel="noopener noreferrer"
					>
						<button
							type="button"
							className="btn tabs"
							id="homepagelearnmore"
						>
							Learn More
						</button>
					</a>
				</span>
			</div>
		</div>
	);
};

export default ToolCard;
