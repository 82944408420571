import React from "react";
import Header from "../../corecomponents/Header";
import ContentPageFooter from "../../corecomponents/ContentPageFooter";
import Livemonthstamp from "../../contentcomponents/Livemonthstamp";
import Livetimestamp from "../../contentcomponents/Livetimestamp";
import Authorsheader from "../../contentcomponents/Authorsheader";
import AboutTheAuthors from "../../contentcomponents/Abouttheauthors";
import Summarytablecard from "../../contentcomponents/Summarytablecard";
import { useState } from "react";
import PartnerDisclaimer from "../../contentcomponents/PartnerDisclaimer";
import Summarytableloadingskeleton from "../../corecomponents/Summarytableloadingskeleton";
import HelmetComponent from "../../seocomponents/helmet";
const Templatearticle = () => {
	const [collapsedItems, setCollapsedItems] = useState({
		collapseOne: false,
		collapseTwo: false,
		collapseThree: false,
		// Add more collapse states for each FAQ item here
	});

	const handleCollapse = (item) => {
		setCollapsedItems((prevState) => ({
			...prevState,
			[item]: !prevState[item],
		}));
	};

	const faqItems = [
		{
			id: "1",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "2",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "3",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "4",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "5",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "6",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "7",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "8",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "9",
			question: "Insert Question",
			answer: "Answer",
		},
	];

	return (
		<div>
			<HelmetComponent
                title='AI Tools To Become More Savvy & Productive'
                description='Discover AI Tools To Become More Savvy & Productive'
                name='Savvy AI Tools'
                type='article' 
            />
			<Header />
			<div className="container">
				<div className="maintextbox">
					<div className="bestofcontentheader">
						<h2 className="bestofminiheader">Trending News</h2>
						<h1 className="bestofh1">Include Article Title</h1>
						<p>Include Article Sub-title</p>
						<div className="header-image-container">
							<img
								src="https://picsum.photos/1200/400"
								alt="Page Header Image"
								className="header-image"
							/>
							<p>
								📸 <strong>Image Credits</strong>: Getty Images
							</p>
						</div>
						<section>
							<Authorsheader />
							<p>
								Last updated on{" "}
								<span id="live-date">
									{" "}
									<Livetimestamp />
								</span>
							</p>
						</section>

						<PartnerDisclaimer />

						<section>
							<h2 className="summarytableheader">
								Insert H2 Header
							</h2>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos. cause.
							</p>
						</section>

						<section>
							<h2 className="summarytableheader">
								Insert H2 Header
							</h2>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos. cause.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos. cause.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
						</section>

						<section>
							<h2 className="summarytableheader">
								Insert H2 Header
							</h2>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos. cause.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos. cause.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
							<ul className="bestofcontentheaderheaderbullets">
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>

								{/* Add more list items as needed */}
							</ul>
							<ol className="bestofcontentheaderheaderbullets">
								<li>New York City</li>
								<li>Paris</li>
								<li>Tokyo</li>
								<li>Rome</li>
								<li>Barcelona</li>
							</ol>
						</section>
						<section>
							<h2 className="summarytableheader">
								Insert H2 Header
							</h2>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos. cause.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos. cause.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
							<ul className="bestofcontentheaderheaderbullets">
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>

								{/* Add more list items as needed */}
							</ul>
						</section>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-lg-9">
						<h1 className="summarytableheader">
							Frequently Asked Questions (FAQ)
						</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-9">
						<div className="accordion">
							{faqItems.map((faqItem) => (
								<div
									className="card border-white"
									key={faqItem.id}
								>
									<div
										className="card-header bg-white"
										id={`faqItem-${faqItem.id}`}
									>
										<h2 className="mb-0">
											<button
												className={`btn btn-link text-dark font-weight-bold collapsible-link ${
													collapsedItems[
														`collapse${faqItem.id}`
													]
														? "collapsed"
														: ""
												}`}
												type="button"
												onClick={() =>
													handleCollapse(
														`collapse${faqItem.id}`
													)
												}
												data-toggle="collapse"
												data-target={`#collapse${faqItem.id}`}
												aria-expanded={
													collapsedItems[
														`collapse${faqItem.id}`
													]
														? "true"
														: "false"
												}
											>
												{faqItem.question}
											</button>
										</h2>
									</div>
									<div
										id={`collapse${faqItem.id}`}
										className={`collapse ${
											collapsedItems[
												`collapse${faqItem.id}`
											]
												? "show"
												: ""
										}`}
										aria-labelledby={`faqItem-${faqItem.id}`}
										data-parent="#accordionExample"
									>
										<div
											className="card-body"
											style={{ marginLeft: "20px" }}
										>
											<p className="font-weight-light m-0">
												{faqItem.answer}
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>{" "}
			<AboutTheAuthors />
			<ContentPageFooter />
		</div>
	);
};

export default Templatearticle;
