import React from "react";

const Summarytablecard = ({ tool }) => {
	const {
		id,
		name,
		image,
		category,
		stars,
		description,
		externalCTA,
		bestfor,
	} = tool;
	return (
		<div className="row" id="summarytablecard">
			<div
				className="col-md-7 d-flex justify-content-center"
				id="summarytablecardimage"
			>
				<a href={externalCTA}>
					<img
						className="img-fluid rounded mb-3 mb-md-0"
						id="summarytablecardimageoutline"
						src={image}
						alt={name}
					/>
				</a>
			</div>
			<div className="col-md-5 my-1 d-flex flex-column align-items-left">
				<h3 className="summarytablecardname">{name}</h3>
				<span className="ratings">
					{Array.from(
						{ length: parseInt(stars, 10) || 0 },
						(_, index) => (
							<i key={index} className="fa fa-star"></i>
						)
					)}
				</span>
				<span className="categoryfont">{category}</span>
				<span className="greatfor">Great for {bestfor}</span>

				<p>{description}</p>

				<a
					className="btn btn-primary"
					id="summarytablecardexternalCTA"
					href={externalCTA}
				>
					Learn More
				</a>
			</div>
		</div>
	);
};

export default Summarytablecard;
