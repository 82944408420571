import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "./AuthContext";

export const ProtectedRoute = ({ children }) => {
	const { currentUser } = UserAuth();
	console.log(currentUser);
	if (!currentUser || currentUser.email != "elienabushosi7@gmail.com") {
		return <Navigate to="/signin" />;
	}
	return children;
};
