import React from "react";
import Header from "../corecomponents/Header";
import Livemonthstamp from "../contentcomponents/Livemonthstamp";
import Livetimestamp from "../contentcomponents/Livetimestamp";
import ContentPageFooter from "../corecomponents/ContentPageFooter";
import Authorsheader from "../contentcomponents/Authorsheader";
import AboutTheAuthors from "../contentcomponents/Abouttheauthors";
import Summarytablecard from "../contentcomponents/Summarytablecard";
import { useState } from "react";
import PartnerDisclaimer from "../contentcomponents/PartnerDisclaimer";
import Summarytableloadingskeleton from "../corecomponents/Summarytableloadingskeleton";
import { roundupinterlink } from "../shared/Roundupinterlinkslist";

const StagePage = ({ TOOLS }) => {
	const Templatetoolid = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	const filteredTools = TOOLS.filter((tool) =>
		Templatetoolid.includes(tool.id)
	);
	const [collapsedItems, setCollapsedItems] = useState({
		collapseOne: false,
		collapseTwo: false,
		collapseThree: false,
		// Add more collapse states for each FAQ item here
	});

	const handleCollapse = (item) => {
		setCollapsedItems((prevState) => ({
			...prevState,
			[item]: !prevState[item],
		}));
	};

	const articleroundupobjectdate1 = roundupinterlink[4];
	const articleroundupobjectdate2 = roundupinterlink[4];
	const articleroundupobjectdate3 = roundupinterlink[9];

	const faqItems = [
		{
			id: "1",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "2",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "3",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "4",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "5",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "6",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "7",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "8",
			question: "Insert Question",
			answer: "Answer",
		},
		{
			id: "9",
			question: "Insert Question",
			answer: "Answer",
		},
	];

	return (
		<div>
			<Header />
			<div className="container">
				<div className="maintextbox">
					<div className="bestofcontentheader">
						<h2 className="bestofminiheader">Best of</h2>
						<h1 className="bestofh1">
							Best Stage Roundup{" "}
							<span id="month-date">
								<Livemonthstamp />
							</span>
						</h1>
						<section>
							<p>
								Compare & discover the best AI Tool for
								engineers, writers, marketers, designers,
								business and many more.
							</p>
							<Authorsheader />
							<p>
								Last updated on{" "}
								<span id="live-date">
									{" "}
									<Livetimestamp />
								</span>
							</p>
						</section>

						<PartnerDisclaimer />

						<section>
							<h2 className="summarytableheader">
								Insert H2 Header
							</h2>
							<p className="summary">Insert Header Paragraph</p>
							<ul className="bestofcontentheaderheaderbullets">
								{filteredTools.map((tool) => (
									<li key={tool.id}>
										<a
											className="comphyp"
											href={tool.externalCTA} // Assuming there's a link property in the tool object
											target="_blank"
										>
											{tool.name}
										</a>
										: Great for {tool.bestfor}
									</li>
								))}
							</ul>
							<p className="summary">
								<strong>Why trust the Savvy Genies?</strong> Our
								editorial team has reviewed more than +1000 AI
								tools and compares them to select the AI tools.
								We chose these tools based on features like
								cost, integration, tasks support, use cases, &
								many more
							</p>
						</section>
						<h2 className="summarytableheader">
							Best AI Tools For Include Category
						</h2>
						<div className="container">
							{!TOOLS || TOOLS.length === 0 ? (
								<Summarytableloadingskeleton />
							) : (
								filteredTools.map((tool) => (
									<Summarytablecard
										key={tool.id}
										tool={tool}
									/>
								))
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="maintextbox">
					<div className="bestofcontentheader">
						<h2 className="summarytableheader">Insert H2 Header</h2>
						<section>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos. cause.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
						</section>
						<h2 className="summarytableheader">Insert H2 Header</h2>
						<section>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos. .
							</p>
						</section>
						<h2 className="summarytableheader">Insert H2 Header</h2>
						<section>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
						</section>
						<h2 className="summarytableheader">
							Insert H2 Header{" "}
						</h2>
						<section>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
							<p className="more">
								» MORE:{" "}
								<a
									className="moreinterlink"
									href={articleroundupobjectdate1.link}
								>
									{articleroundupobjectdate1.icon}{" "}
									{articleroundupobjectdate1.title}{" "}
								</a>
							</p>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
						</section>
						<h2 className="summarytableheader">Insert H2 Header</h2>
						<section>
							<p className="summary">
								AI coding tools are valuable across
								organizations, teams and experience levels:
							</p>
							<ul className="bestofcontentheaderheaderbullets">
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>

								{/* Add more list items as needed */}
							</ul>
						</section>
						<p className="more">
							» MORE:{" "}
							<a
								className="moreinterlink"
								href={articleroundupobjectdate2.link}
							>
								{articleroundupobjectdate2.icon}{" "}
								{articleroundupobjectdate3.title}{" "}
							</a>
						</p>
						<section>
							<h2 className="summarytableheader">
								Insert H2 Header
							</h2>
							<p className="summary">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Integer nec odio. Praesent
								libero. Sed cursus ante dapibus diam. Sed nisi.
								Nulla quis sem at nibh elementum imperdiet. Duis
								sagittis ipsum. Praesent mauris. Fusce nec
								tellus sed augue semper porta. Mauris massa.
								Vestibulum lacinia arcu eget nulla. Class aptent
								taciti sociosqu ad litora torquent per conubia
								nostra, per inceptos himenaeos.
							</p>
							<ul className="bestofcontentheaderheaderbullets">
								<li>
									Code transformation - AI could automatically
									refactor, optimize, and translate codebases
									between languages.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>
								<li>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Integer nec odio.
								</li>

								{/* Add more list items as needed */}
							</ul>
						</section>
						<p className="summary">
							<strong>Why trust the Savvy Genies?</strong> Our
							editorial team has reviewed more than +1000 AI tools
							and compares them to select the AI tools. We chose
							these tools based on features like cost,
							integration, tasks support, use cases, & many more
						</p>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-lg-9">
						<h1 className="summarytableheader">
							Frequently Asked Questions (FAQ)
						</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-9">
						<div className="accordion">
							{faqItems.map((faqItem) => (
								<div
									className="card border-white"
									key={faqItem.id}
								>
									<div
										className="card-header bg-white"
										id={`faqItem-${faqItem.id}`}
									>
										<h2 className="mb-0">
											<button
												className={`btn btn-link text-dark font-weight-bold collapsible-link ${
													collapsedItems[
														`collapse${faqItem.id}`
													]
														? "collapsed"
														: ""
												}`}
												type="button"
												onClick={() =>
													handleCollapse(
														`collapse${faqItem.id}`
													)
												}
												data-toggle="collapse"
												data-target={`#collapse${faqItem.id}`}
												aria-expanded={
													collapsedItems[
														`collapse${faqItem.id}`
													]
														? "true"
														: "false"
												}
											>
												{faqItem.question}
											</button>
										</h2>
									</div>
									<div
										id={`collapse${faqItem.id}`}
										className={`collapse ${
											collapsedItems[
												`collapse${faqItem.id}`
											]
												? "show"
												: ""
										}`}
										aria-labelledby={`faqItem-${faqItem.id}`}
										data-parent="#accordionExample"
									>
										<div
											className="card-body"
											style={{ marginLeft: "20px" }}
										>
											<p className="font-weight-light m-0">
												{faqItem.answer}
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>{" "}
			<AboutTheAuthors />
			<ContentPageFooter />
		</div>
	);
};

export default StagePage;
