import React, { useEffect } from "react";

const Livetimestamp = () => {
	useEffect(() => {
		const dateElement = document.getElementById("live-date");

		const updateDate = () => {
			const currentDate = new Date();
			const formattedDate = currentDate.toLocaleDateString("en-US", {
				year: "numeric",
				month: "long",
				day: "numeric",
			});
			dateElement.textContent = formattedDate;
		};

		updateDate();

		const interval = setInterval(updateDate, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return <span id="live-date"></span>;
};

export default Livetimestamp;
