import React from "react";

const Summarytableloadingskeleton = () => {
	const numberOfLoadingCards = 8; // Number of loading cards you want

	return (
		<div>
			{/* Loop to render 8 loading cards */}
			{Array.from({ length: numberOfLoadingCards }).map((_, index) => (
				<div className="product-card-skeleton" key={index}>
					<div className="product-card-skeleton-image"></div>
					<div className="product-card-skeleton-details">
						<div className="product-card-skeleton-title"></div>
						<div className="product-card-skeleton-description"></div>
						<div className="product-card-skeleton-price"></div>
					</div>
				</div>
			))}
		</div>
	);
};

export default Summarytableloadingskeleton;
