import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./corecomponents/Header";
import HomePageTopContainer from "./corecomponents/HomePageTopContainer";
import ToolCardRenderer from "./corecomponents/ToolCardRenderer";
import React, { useState, useEffect } from "react";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
// import { TOOLS } from "./shared/TOOLS";
import Import from "./corecomponents/Import";
import { AuthProvider } from "./corecomponents/AuthContext";
import { ProtectedRoute } from "./corecomponents/ProtectedRoute";
import {
	doc,
	setDoc,
	getDoc,
	collection,
	getDocs,
	addDoc,
} from "firebase/firestore";
import { db, auth } from "./firebase";
import Footer from "./corecomponents/Footer";
import "./App.css";
import SignIn from "./corecomponents/SignIn";
import StagePage from "./pages/StagePage";
// Roundup Imports //
import Templateroundup from "./pages/best/template";
import Engineersroundup from "./pages/best/engineers";
import Writersroundup from "./pages/best/writers";
import Marketersroundup from "./pages/best/marketers";
import Imageroundup from "./pages/best/image";
import Audioroundup from "./pages/best/audio";
import Businessroundup from "./pages/best/business";
import Videoroundup from "./pages/best/video";
import GraphicDesignerroundup from "./pages/best/graphic-designer";
import Productivityroundup from "./pages/best/productivity";
import Lifestyleroundup from "./pages/best/lifestyle";
// Article Imports //
import Templatearticle from "./pages/articles/templatearticle";
import AIreplacejobs from "./pages/articles/AIreplacejobs";
import Aiwritingisbooming from "./pages/articles/AI-writing-booming";
import BoominImageGen from "./pages/articles/The-Boom-In-Image-Generators";
import Programingasweknowit from "./pages/articles/Programming-As-We-Knew-It-Is-Over";
import Magicofvideogen from "./pages/articles/The-Magic-of-Video-Generators-by-AI";
import Creatingaudioandvideo from "./pages/articles/Using-AI-to-Create-Video-and-Audio";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from "react-redux";
import { setTools as setSearchTools } from "./store/slices/searchSlice";

const App = () => {
	const dispatch = useDispatch()
	const [state, setState] = useState([]);
	const [TOOLS, setTools] = useState([]);
	useEffect(() => {
		const collectionsRef = collection(db, `TOOLS`);
		getDocs(collectionsRef)
			.then((docSnap) => {
				const tmpQuestions = docSnap.docs.map((d) => d.data());
				// console.log(tmpQuestions);
				dispatch(setSearchTools(tmpQuestions))
				setState(tmpQuestions);
				setTools(tmpQuestions);
			})
			.catch((e) => console.log(e.message));
	}, []);
	const helmetContext = {};
	return (
		<div className="App">
			<HelmetProvider context={helmetContext}>
			<AuthProvider>
				<Routes>
					<Route
						path="/"
						element={
							<div className="grayfrontpage">
								<Header />
								<HomePageTopContainer
									// state={state}
									setState={setState}
									TOOLS={TOOLS}
								/>
								<ToolCardRenderer state={state} />
								<Footer />
							</div>
						}
					/>
					<Route path="/signin" element={<SignIn />} />
					<Route
						path="/import"
						element={
							<ProtectedRoute>
								<Import />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/engineers"
						element={<Engineersroundup TOOLS={TOOLS} />}
					/>{" "}
					<Route
						path="/writers"
						element={<Writersroundup TOOLS={TOOLS} />}
					/>{" "}
					<Route
						path="/template"
						element={<Templateroundup TOOLS={TOOLS} />}
					/>
					<Route
						path="/marketers"
						element={<Marketersroundup TOOLS={TOOLS} />}
					/>
					<Route
						path="/image"
						element={<Imageroundup TOOLS={TOOLS} />}
					/>
					<Route
						path="/audio"
						element={<Audioroundup TOOLS={TOOLS} />}
					/>
					<Route
						path="/business"
						element={<Businessroundup TOOLS={TOOLS} />}
					/>
					<Route
						path="/video"
						element={<Videoroundup TOOLS={TOOLS} />}
					/>
					<Route
						path="graphic-designer"
						element={<GraphicDesignerroundup TOOLS={TOOLS} />}
					/>
					<Route
						path="productivity"
						element={<Productivityroundup TOOLS={TOOLS} />}
					/>
					<Route
						path="lifestyle"
						element={<Lifestyleroundup TOOLS={TOOLS} />}
					/>
					<Route path="/AIreplacejobs" element={<AIreplacejobs />} />
					<Route
						path="/Aiwritingisbooming"
						element={<Aiwritingisbooming />}
					/>
					<Route
						path="/The-Boom-In-Image-Generators"
						element={<BoominImageGen />}
					/>
					<Route
						path="Programming-As-We-Knew-It-Is-Over"
						element={<Programingasweknowit />}
					/>
					<Route
						path="The-Magic-of-Video-Generators-by-AI"
						element={<Magicofvideogen />}
					/>
					<Route
						path="Using-AI-to-Create-Video-and-Audio"
						element={<Creatingaudioandvideo />}
					/>
					<Route
						path="/templatearticle"
						element={<Templatearticle />}
					/>
					<Route
						path="/StagePage"
						element={<StagePage TOOLS={TOOLS} />}
					/>
					{/* Add this route */}
				</Routes>
			</AuthProvider>
			</HelmetProvider>
		</div>
	);
};

export default App;
