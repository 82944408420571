export const roundupinterlink = {
	1: {
		title: "Best AI Writing Tools For Writers",
		link: "https://savvygenies.com/writers",
		icon: "✏️",
	},
	2: {
		title: "Best AI Tools For Engineers",
		link: "https://savvygenies.com/engineers",
		icon: "💻",
	},
	3: {
		title: "Best AI Tools For Image Generation",
		link: "https://savvygenies.com/image",
		icon: "🖼️",
	},
	4: {
		title: "Best AI Tools for Video Generation",
		link: "https://savvygenies.com/video",
		icon: "📽️",
	},
	5: {
		title: "Best AI Tools for Audio Generation",
		link: "https://savvygenies.com/audio",
		icon: "🔊",
	},
	6: {
		title: "Best AI Tools For Graphic Designers",
		link: "https://savvygenies.com/graphic-designer",
		icon: "🎨",
	},
	7: {
		title: "Best AI Tools For Assistant",
		link: "https://savvygenies.com/",
		icon: "💬",
	},
	8: {
		title: "Best AI Tools For Productivity",
		link: "https://savvygenies.com/productivity.",
		icon: "✅",
	},
	9: {
		title: "Best AI Tools For Your Lifestyle Needs",
		link: "https://savvygenies.com/lifestyle",
		icon: "✌️",
	},
	10: {
		title: "Best AI Tools For Business",
		link: "https://savvygenies.com/business",
		icon: "📈",
	},
	11: {
		title: "Best AI Tools For Education & Learning",
		link: "https://savvygenies.com/",
		icon: "📚",
	},
	12: {
		title: "Best AI Tools For Marketers",
		link: "https://savvygenies.com/marketers",
		icon: "📣",
	},
};

export default roundupinterlink;
