import React from "react";

const PartnerDisclaimer = () => {
	return (
		<div className="text-box">
			<p className="graydisclaimerbox">
				Many or all of the products featured here are from our AI
				partners who compensate us. This may influence which products we
				write about and where and how the product appears on a page.
				However, this does not influence our evaluations. Our opinions
				are our own.
			</p>
		</div>
	);
};

export default PartnerDisclaimer;
