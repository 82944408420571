import React, { useEffect, useState } from "react";

const Livemonthstamp = () => {
	const [formattedDate, setFormattedDate] = useState("");

	useEffect(() => {
		const updateDate = () => {
			const currentDate = new Date();
			const formattedDate = currentDate.toLocaleDateString("en-US", {
				year: "numeric",
				month: "long",
			});
			setFormattedDate(formattedDate);
		};

		updateDate();

		const interval = setInterval(updateDate, 24 * 60 * 60 * 1000); // Update every day

		return () => {
			clearInterval(interval);
		};
	}, []);

	return <span id="month-date">{formattedDate}</span>;
};

export default Livemonthstamp;
