import React from "react";

const Authorsheader = () => {
	return (
		<p>
			By{" "}
			{/* <a
				className="hyperlinkauth"
				href="https://www.linkedin.com/in/harry-atlas-a6905b294/03b7bb189/"
				target="_blank"
				rel="noopener noreferrer"
			>
				Harry Atlas
			</a>{" "}
			and{" "} */}
			<a
				className="hyperlinkauth"
				href="https://www.linkedin.com/in/elie-nabushosi-mba-65308910a/"
				target="_blank"
				rel="noopener noreferrer"
			>
				Elie Nabushosi
			</a>
		</p>
	);
};

export default Authorsheader;
