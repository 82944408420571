import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { IoMdClose, IoIosSearch } from "react-icons/io";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const SearchModal = () => {
    const tools = useSelector(state => state.search.tools)
    const [show, setShow] = useState(false);
    const [ posts, setPosts ] = useState([])
    const [ query, setQuery ] = useState('')
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
    useEffect(() => {
        if(query){
            setPosts(tools.filter((question) =>
            question.name.toLowerCase().includes(query.toLowerCase())).
                sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                const queryIndexA = nameA.indexOf(query.toLowerCase());
                const queryIndexB = nameB.indexOf(query.toLowerCase());
                    
                if (queryIndexA === queryIndexB) {
                  return nameA.localeCompare(nameB);
                }
        
                if (queryIndexA === -1) {
                  return 1;
                }
        
                if (queryIndexB === -1) {
                  return -1;
                }
        
                return queryIndexA - queryIndexB;
              }))
        }
    },[query])
  return (
    <div className='col-lg-5 col-md-12'>
      <Button variant="outlined" onClick={handleShow}>
        <IoIosSearch size={30}/>
      </Button>
        <Modal size='lg'  contentClassName="w-100 h-50 p-1 rounded mx-auto" style={{ paddingTop:'150px'}} show={show} onHide={handleClose}>
            <Modal.Body className='p-0'>
                <InputGroup className='' size='sm'>
                    <InputGroup.Text className='btn-outlined' id="basic-addon1" onClick={() => query && setQuery('')}>
                        {query ? <IoMdClose size={30}/>:<IoIosSearch size={30}/> } 
                    </InputGroup.Text>
                    <Form.Control
                    autoFocus
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search posts, tags, and authors"

                    />
                </InputGroup>
                {query && posts.length > 0 &&
                  <div className='mt-2' style={{ height:'fit-content', maxHeight:'70vh', overflowY:'auto' }}>
                    <div className='px-2 fs-6 fw-bold text-secondary'>Posts</div>
                    {
                        posts.map((temp, index) => 
                        <a href={temp.externalCTA} target="_blank" rel="noopener noreferrer">
                          <div key={index} className='p-2'>
                              <div className='fs-6 fw-bold'>{temp.name}</div>
                              <div className='text-secondary text-truncate' >
                                  {temp.description}
                              </div>
                          </div>
                        </a>

                        
                        )
                    }
                  </div>
                }
                
            </Modal.Body>
        </Modal>
    </div>
    
  )
}

export default SearchModal



