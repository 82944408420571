import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "./AuthContext";
const SignIn = () => {
	let navigate = useNavigate();
	let [error, setError] = useState("");
	let emailRef = useRef();
	let passwordRef = useRef();
	let { currentUser, login } = UserAuth();
	let handleSignIn = async () => {
		await login(emailRef.current.value, passwordRef.current.value)
			.then(() => {
				if (emailRef.current.value == "elienabushosi7@gmail.com") {
					navigate("/import");
				} else navigate("/");
			})
			.catch((error) => setError(error?.message));
	};
	return (
		<div className="wrapper position-relative">
			<div className="container">
				{error && <h3>{error}</h3>}
				<div
					className="multisteps_form position-relative overflow-hidden"
					id="wizard"
				>
					<div className="row">
						<div className="col-md-12">
							<div className="form_header_content text-center pt-4">
								<h2>Login</h2>
								<div className="auth-form">
									<input
										className="my-input"
										type="email"
										ref={emailRef}
										placeholder="Email"
									/>
									<input
										className="my-input"
										type="password"
										ref={passwordRef}
										placeholder="Password"
									/>
									{/* <a style={{textDecoration:"underline", color: "blue", textAlign:"right"}} onClick={() => navigate("/forgot-password")}>Forgot Password?</a> */}
									<button
										style={{ alignSelf: "center" }}
										className="theme-btn"
										onClick={() => handleSignIn()}
									>
										{" "}
										Sign In{" "}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
