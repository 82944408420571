// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref, listAll } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyDYGD2UYBs74WqdIhOS6IVvpFql1LEmn14",
	authDomain: "savvy-genies.firebaseapp.com",
	projectId: "savvy-genies",
	storageBucket: "savvy-genies.appspot.com",
	messagingSenderId: "936687965876",
	appId: "1:936687965876:web:cbafbba2fb67bebd6129e1",
	measurementId: "G-NJ5WSF7Z5M",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default getFirestore();
