import React, { useState } from "react";

const DropdownMenu = ({ title, items }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleMouseEnter = () => {
		setIsOpen(true);
	};

	const handleMouseLeave = () => {
		setIsOpen(false);
	};

	// Object that maps each title to its corresponding emoji Unicode character
	const titleIcons = {
		"For Engineers": "👩🏽‍💻",
		"For Writers": "✍🏼",
		"For Marketers": "📣",
		"For Image Gen": "🖼️",
		"For Business": "💰",
		"For Audio": "🔈",
		"For Video": "📽️",
	};

	return (
		<div
			className="dropdown mr-3"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<button
				className="btn btn-primary dropdown-toggle"
				type="button"
				data-toggle="dropdown"
				aria-expanded={isOpen}
			>
				<span className="emoji-icon">{titleIcons[title]}</span>{" "}
				{/* Display the emoji icon */}
				{title} {/* Display the title */}
			</button>
			{isOpen && (
				<div
					className="dropdown-menu"
					aria-labelledby="dropdownMenuButton"
				>
					{items.map((item, index) => (
						<a className="dropdown-item" href="#" key={index}>
							{item}
						</a>
					))}
				</div>
			)}
		</div>
	);
};

export default DropdownMenu;
